//colors 
$dark-grey: #18181B; //#33CCCC
$white: #FFFFFF;
$orange: #FC5331;
$blue: #00A6FF;
$black: #040301;
$yellow: #f1cb72;
$light-grey: #8C8C8C;
$font-family-sans-serif: urbane, sans-serif;
// 2021 colors
$pastel-pink: #fdada2;
$deep-sky: #1b6578;
$teal: #378286;
$pastel-yellow: #ffe0a2;
$pastel-orange: #fdad56;

// Fonts
.mt-1 { margin-top: 1em; }
.mt-2 { margin-top: 2em; }
.mt-3 { margin-top: 3em; }
.mt-4 { margin-top: 4em; }
.mt-5 { margin-top: 5em; }

.mb-1 { margin-bottom: 1em; }
.mb-2 { margin-bottom: 2em; }
.mb-3 { margin-bottom: 3em; }
.mb-4 { margin-bottom: 4em; }
.mb-5 { margin-bottom: 5em; }

.pt-1 { padding-top: 1em; }
.pt-2 { padding-top: 2em; }
.pt-3 { padding-top: 3em; }
.pt-4 { padding-top: 4em; }
.pt-5 { padding-top: 5em; }

.pb-1 { padding-bottom: 1em; }
.pb-2 { padding-bottom: 2em; }
.pb-3 { padding-bottom: 3em; }
.pb-4 { padding-bottom: 4em; }
.pb-5 { padding-bottom: 5em; }

// Fonts 
// Urbane Bold
// font-family: urbane, sans-serif;
// font-weight: 700;
// font-style: normal;


// Urbane Demi Bold
// font-family: urbane, sans-serif;
// font-weight: 600;
// font-style: normal;


// Urbane Light
// font-family: urbane, sans-serif;
// font-weight: 300;
// font-style: normal;


// Urbane Light Italic
// font-family: urbane, sans-serif;
// font-weight: 300;
// font-style: italic;