.Nav-18 {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 45px;
	background-color: black;
	position: fixed;
	z-index: 10;
	.Nav-18-left {
		display: flex;
		.Logo-18 {
			width: 42px;
			height: 42px;
			margin: 0 1em;
			img {
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
		.Nav-title {
			color: white;
			text-transform: uppercase;
			font-family: "Open Sans", sans-serif;
			margin-top: 14px;
			@media only screen and (max-width: 768px) {
				display: none;
			}
		}

	}
	.Nav-18-right {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		.sound-icon {
			width: 40px;
			margin: 0 1em;
			&:hover {
				cursor: pointer;
			}
		}

	}

	.genre-list-container {

		.genre-list-title {
			font-family: "Open Sans", sans-serif;
			display: none;
			margin-right: 3em;
		}
		.genre-list {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			position: relative;
			padding: 8px 0;
			li {
				border: 1px solid white;
		    color: white;
		    padding: 0 10px;
		    border-radius: 20px;
		    margin: 0 0.5em;
		    line-height: 27px;
				&:hover {
					color: black;
					background-color: white;
					cursor: pointer;
				}
			}
			li.selected {
				color: black;
				background-color: $blue;
				border: 1px solid;
				&:before {
					content: "\00d7 ";
				}
			}
			li.all {
				border: none;
				text-transform: lowercase;
				&:before {content: "clear ";}
			}
		}
		@media only screen and (max-width: 768px) {
			display: inline-block;
			position: relative;
			margin-top: 14px;
			.genre-list-title {
				display: block;
				color: white;
			}
			.genre-list {
				display: none;
				list-style: none;
				position:absolute;
				top: 100%;
				left: -30px;
			}
			&:hover {
				.genre-list {
					display: inline-block;
					background-color: black;
					width: 130px;
					li {
						margin: 10px;
					}
					li.selected {
						color: black;
						background-color: $blue;
						border: 1px solid;

						&:before {
							content: "";
						}
					}
				}
			}
		}
	}
	
}


.Navburger-18 {
  //margin-right: 40px;
  margin:0 2em;
  color: white;
  .dropdown-menu {
  	.dropdown-menu-item {
  		display: block;
  		.dropdown-link {
  			color: white;
  			&:hover { color: limegreen; }
  		}
  	}
  }
}
.Navburger-18 > ul > li > a,.dropdown-menu-item > a {
  text-decoration: none;
  padding: 20px;
  display: inline-block;
  color: white;
  transition: all 0.4s;
}

.Navburger-18 a:hover,.dropdown-menu-item:hover{
  background: black;
  color: limegreen;
}

.dropdown-menu {
	text-align: right;
  position: absolute;
  display: none;
  list-style-type: none;
  background: black;
  right: -32px;
}

.dropdown-toggle:hover > .dropdown-menu {
  display: block;
}

.dropdown-toggle {
	list-style: none;
  position: relative;
  top: 14px;
  .dropdown-title {
  	color: white;
  }
}

.dropdown-toggle:after{
  content: "\0025BC";
  position: absolute;
  right: -20px;
  top: 4px;
  font-size: 12px;
  color: white;
}
