.Footer-18 {
	background-color: black;
	color: white;
	font-size: 0.8rem;
	opacity: 1;
	font-family: "Open Sans", sans-serif;
	padding: 2em 1em 1em 1em;
	.footer-links-container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;

	}
	.footer-links-item {
		h4 {
			font-family: "Open Sans", sans-serif;
			font-size: 1em;
			color: white;
			margin: 0 0 1em 0;
		}
		margin: 0 1em;
	}
	.footer-link-div {
		margin: 1rem 0;
	}

	a.footer-link {
		color: white;
		&:hover {
			text-decoration: none;
			color: $blue;
		}
	}
}

.bottom-span {
  padding: 1em 2rem 0;
  text-align: center;
  background-color: black;
}

.Footer-18 {
	background-color: black;
	color: white;
	font-size: 0.8rem;
	opacity: 1;
	.footer-links-container {
		display: flex;
		justify-content: center;
	}
	.footer-links-item {
		h4 {
			font-size: 1em;
			color: white;
			margin: 0 0 1em 0;
		}
	}
	a.footer-link {
		color: white;
	}
}

// ===========
// footer 2017 styles
// ===========
.footer {
  // margin-left: 27%;
  // text-align: center;
} 

.footer-links-item {
}

.bottom-span {
  // padding-bottom: 2rem; 
  // text-align: center;
  // background-color: #000000;
}

.footer-link-div {
 // margin-bottom: 0.7rem;
}

a.footer-link:hover {
  //text-decoration: underline;
  //text-underline-position: under;
  //color: $light-grey;
}