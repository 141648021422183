// ====================
// Main list container
// ====================
.Intro-container {
	display: grid;
	margin-bottom: 6em 0;
	p > a {
		// theres some messed up / overlapping styles happening. need to sort out later. 
		font-size: 1em;
	}

	.Playlist-group-decades {
		background-color: #d2e9ff;
	}
	.Playlist-group-twenty {
		background-color: #eeeeee;
	    padding-bottom: 3em !important;
	}
	.Playlist-group-twenty.twenty-one {
		background-color: $pastel-yellow;
	}
	.Playlist-group-decades, .Playlist-group-twenty {
	  	margin-top: 2em;
	    padding: 1em;
	  	p {
	  		a {
    			text-decoration: none;
    			&:hover {
    				color: $blue;
    			}
    			&:before {
    				content: "\2192 "; 
    			}
    			span { 
    				margin-left: 10px; 
    				font-size: 1.1em;
    				text-decoration: underline;
    			}
	  		}
	  	}
    }
}

.List-container_decades {
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 40px;
	justify-items: center;
	.Intro-18 {
		margin-top: 3em;
	}

	.first-line {
		text-transform: uppercase;
		font-weight: 600;
	}
	.Jump-nav {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		position: sticky;
		top: 44px;
		background-color: white;

		// transform: translate(25%, -100%);


		a {
			text-decoration: none;
			padding: 5px;
			text-align: center;
			font-family: Urbane, sans-serif;
			border: 1px solid black;
			&:hover {
				background-color: black;
				color: white;
			}
		}
	}
	@media only screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
}

.Intro-card-decades {
	max-width: 800px;
	margin: 1.5em auto;
}
.Intro-text {
	.first-line {
		font-family: 'Urbane', sans-serif;
		font-weight: 800; 
	}
	font-family: 'Georgia', serif;
  margin-top: 2em;
  line-height: 1.2em;
  font-size: 1em;
  h5 {
  	margin: 1em 0 0.5em 0;
  }
  p {
  	margin-bottom: 1em;
  }
  .playlist-group {
  	margin-top: 2em;
    background-color: #eaeaea;
    padding: 1em;
  	h5 {
  		a {
  			font-family: "Helvetica", sans-serif;
  			font-weight: 400;
  			font-size: 1em;
  			text-decoration: none;
  			&:hover {
  				color: $blue;
  			}
  			&:before {
  				content: "\2192 "; 
  			}
  		}
  	}
  }
}
.Intro-text-twenty {
	p {
		margin-top: 1em;
	}
	h3.twenty {
		margin-top: 1em;
	    margin-bottom: 7px;
	    font-size: 1.5em !important;
	    font-weight: 600;
	}
	ul, ul > li > a {
		font-size: 1em;
	}
}

// ====================
// song card
// ====================
.Song-card-decades {
	margin: 6em 0;
}
.card-decades {
	padding: 1em 0;
	display: grid;
	grid-template-columns: 200px auto;
	grid-gap: 40px;
	//min-height: 500px;
	.card-col-1 {
		.h1 {
			font-size: 6em;
			text-align: right;
		}
		img {
			width: 100%;
			height: auto;
			margin-top: 1em;
		}
	}
	.card-col-2 {
		.title-text {
			h2 {
				font-weight: 600;
		    margin: 6px 0 10px;
			}
		}
		.details {
			margin: 17px 0 0;
		    font-family: 'Urbane', sans-serif;
		    color: $light-grey;
		    z-index: 10;
		}
		.description.p {
		padding-right: 1em;
		  margin-top: 1em;
		  line-height: 1.2em;
		  font-size: 1.1em;
		  a, p {
		  	margin: 1em 0;
		  	font-size: inherit;
		  }
		}
		.listen {
			font-size: 1em;
			margin-top: 1em;
		}
	}
}
.twenty {
	.details {
		display: none;
	}
}
@media only screen and (max-width: 768px) {
	.Song-card-decades {
		//margin: 4em 0
	}
	.card-decades {
		z-index: 9;
		.title-text {
			h2 {
				font-size: 1.2em;
			}
		}
		p {
			line-height: 1em;
			margin-top: 0.5em;
			font-size: 1em;
		}
		.description {
			margin: 1em 0;
			padding-right: 0;
			font-size:1em;
			p {
				font-size: 1em;
			}
		}
	}
	.card-decades, .List-container {
		grid-template-columns: 1fr;
	}
	.details {
		font-size: 0.85em;
		margin: 1em 0 0;
	}
	.listen {
		font-size: 1em;
		.p {
			font-size: 1em;
		}
	}
}


