input { //tabs__squares
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: $blue;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken($blue, 10%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $blue;
    background: white;
    transition: all .35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $blue;
    cursor: pointer;
    &:hover {
      background: darken($blue, 10%);
    }
  }
}
.tabs__squares {
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	//width: 300px;
	&-label {
		height: 250px;
	}
}

// :checked
input:checked {
  + .tab-label {
    background: darken($blue, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}



