.Hero__decades {
	width: 100%;
	height: 100vh;
	background-color: #609cef;
	color: black;
	display: grid;
	grid-gap: 2em;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 4fr 1fr;
	.Hero__album {
		width: 300px;
		height: 300px;
		background: url('https://res.cloudinary.com/ddvn6aemk/image/upload/v1576823716/dbrad2010s/raingurl.jpg');
		background-size: cover;	
		align-self: center;
		justify-self: end;
	}
	.Hero__title-wrapper {
		align-self: center;
		max-width: 300px;
		h1 {
			font-family: 'urbane', sans-serif;
			font-weight: 800;
		}
		.yellow {
			color: $yellow; //#f1cb72
		}
		.outline {
			-webkit-text-stroke: 2px black; 
			text-stroke: 2px black;
			color: #609cef;
		}
	}

}

.Hero__2020 {
	width: 100%;
	height:100vh;
	background: url('https://res.cloudinary.com/ddvn6aemk/image/upload/c_limit,w_1500/v1609138385/dbrad2020/hero-edit_2x.png');
	background-size: cover;	
	background-position: 50% 25%;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-rows: 4fr 1fr 1fr;
}

.Hero__2021 {
	width: 100%;
	height:100vh;
	background: url('https://res.cloudinary.com/ddvn6aemk/image/upload/v1640845552/dbrad2021/hero_2021.jpg');
	background-size: cover;	
	background-position: 50% 25%;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-rows: 4fr 1fr 1fr;
}

/// buttons
.Hero__sound-text {
	text-align: right;
}
.Hero__sound-text.twenty {
	font-family: 'Urbane', sans-serif;
	font-weight: 600;
	font-size: 1.3em;
	color: white;
	margin-top: 1em;
	text-align: left !important;
    margin-left: 5px;
}

.Hero__sound-selection-container {
	justify-self: center;
	display: -webkit-box; 
	display: -ms-flexbox; 
	display: flex; 
	-webkit-box-pack: center; 
	-ms-flex-pack: center; 
	justify-content: center;
	.sound-selection {
		font-family: "Urbane", sans-serif;
		border: 2px solid black;
		margin: 0 0.5em;
		padding: 1em;
		height: 1em;
		//font-weight: 600;
		font-size: 1.2em;
		cursor: pointer;
		&.on {
			&:hover {
				background-color: $yellow;
			}
		}
		&.off {
			&:hover {
				background-color: $orange;
			}
		}
	}
	.sound-selection.twenty {
		background-color: white;
		font-weight: 600;
		border: 4px solid black;
		margin: 5px;
	}
	.sound-selection-yellow.on {
		&:hover {
			background-color: #ffec06;
		}
	}
	.sound-selection-blue.off {
		&:hover {
			background-color: #2547d6;
			color: white;
		}
	}
	.sound-selection.twenty {
		background-color: white;
		font-weight: 600;
		border: 4px solid black;
		margin: 5px;
	}
	.sound-selection.twenty-one {
		font-weight: 600;
		border: 4px solid $deep-sky;
		color: $deep-sky;
		margin: 5px;
		font-style: italic;
	}
	.sound-selection__pastel-pink {
		background-color: $pastel-pink;
		&:hover {
			background-color: darken($pastel-pink, 10%);
			color: $deep-sky;
		}
	}
	.sound-selection__pastel-yellow {
		background-color: $pastel-yellow;
		&:hover {
			background-color: darken($pastel-yellow, 10%);
			color: $deep-sky;
		}
	}
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

/// mobile
@media only screen and (max-width: 768px) {
	.Hero__decades, .Hero__2020, .Hero__2021 {
		grid-template-columns: 1fr;
		grid-template-rows: 3fr 1fr 1fr 1fr;
		grid-gap: 10px;
		.Hero__title-wrapper {
			h1 {
				font-size: 3em;
			}
		}
		.Hero__album {
			height: 190px;
			width: 200px;
		}
		h2.Hero__sound-text {
			font-size: 1.4em;
	    text-align: left;
	    align-self: center;
		}
		.Hero__sound-selection-container {
			text-anchor: center;
			display: flex;
			justify-content: space-between !important;
			.sound-selection {
				margin: 0 5px;
				padding: 15px 7px;
		    	height: fit-content;
		    	font-size: 1.1em;
			}
		}
	}
	.Hero__2020, .Hero__2021 {
		grid-template-columns: 1fr;
		grid-template-rows: 3fr 1fr 1fr;
		.Hero__sound-selection-container.twenty {
			display: block;
			.sound-selection {
				margin: 5px;
			}
		}
	}
	.Hero__2021 {
		width: 100%;
		height:100vh;
		background: url('https://res.cloudinary.com/ddvn6aemk/image/upload/v1640846794/dbrad2021/hero_2021-m.jpg');
		background-size: cover;	
		background-position: 50% 25%;
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 1fr;
		grid-template-rows: 4fr 1fr 1fr;
	}
}
@media only screen and (max-width: 600px) {
	.Hero__2021 {
		width: 100%;
		height:100vh;
		background: url('https://res.cloudinary.com/ddvn6aemk/image/upload/v1640847413/dbrad2021/hero_2021-m_xs.jpg');
		background-size: cover;	
		background-position: 50% 25%;
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 1fr;
		grid-template-rows: 4fr 1fr 1fr;
	}
	.Hero__sound-text.twenty {
		font-size: 1em;
	    margin-left: 0.5em;
	}
}


.imageCredit {
	color: #870000 !important;
	font-size: 10px !important;
	margin-bottom: 5px;
	margin-right: 5px;
	text-align: right;
	a {
		color: #870000 !important;
		font-size: 10px !important;
	}
}
