@import 'variables';
@import 'hero';
@import 'list';
@import 'navbar';
@import 'footer';
@import 'accordion';

html, body {
	background-color: #ffffff;
	::selection {
	  background: $yellow; /* WebKit/Blink Browsers */
	}
	::-moz-selection {
	  background: $yellow; /* Gecko Browsers */
	}
}

.Landing {
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	align-content: center;
	font-size: 2em;
	font-family: 'Urbane', sans-serif;
	font-weight: 800;
	background-color: black;
	width: 100vw;
	height: 100vh;
	a { 
		text-decoration:none;
		color: white;
		&:hover {
			text-decoration: underline;
		}
	}
}

.App-decades {
	.Main {
	    padding: 1em;
	    max-width: 1200px;
	    margin: 0 auto;
	}
	h1, h2, h3, h4, h5, h6, p, a {
		color: #000000;
		//font-family: 'Helvetica', sans-serif;
		font-family: 'Urbane', sans-serif;
	}
	h1 { font-weight: 900; font-size: 4em; }
	h2 { font-weight: 700; font-size: 3.5em; }
	h3 { font-weight: 700; font-size: 2.9em; }
	h4 { font-weight: 300; font-size: 2em; }
	h5 { font-weight: 300; font-size: 1em; }
	h6 { font-weight: 300; font-size: 0.8em; }

	.h1, .h2, .h3, .h4, .h5, .h6, .p, .a {
		color: #000000;
		//font-family: 'Helvetica', sans-serif;
		font-family: 'Urbane', sans-serif;
	}

	.h1 { font-weight: 900; font-size: 4em; }
	.h2 { font-weight: 700; font-size: 3.5em; }
	.h3 { font-weight: 700; font-size: 2.9em; }
	.h4 { font-weight: 300; font-size: 2em; }
	.h5 { font-weight: 300; font-size: 1em; }
	.h6 { font-weight: 300; font-size: 0.8em; }

	p, ul, li, a, .p {
		font-family: "Georgia", serif;
		font-size: 1.1em;
	}

	em {
		font-style: italic;
	}

	.font-weight-light {
		font-weight: 300;
	}

	a:hover {
		color: $blue;
	}

	@media only screen and (max-width: 768px) {
		h1 { font-weight: 600; font-size: 3.5em; }
		h2 { font-weight: 500; font-size: 3.25em; }
		h3 { font-weight: 500; font-size: 2.5em; }
		h4 { font-weight: 400; font-size: 1.75em; }
		h5 { font-weight: 400; font-size: 1em; }
		h6 { font-weight: 400; font-size: 0.8em; }
	}
}

// #videoInView {
// 	position: fixed;
// 	height: 500px;
// 	width: 100vw;
// 	background-color: black;
// 	opacity: 0;
// 	top: -25%;
// 	z-index: -1;
// }

#videoInView_decades {
	position: fixed;
	height: 100px;
	width: 100vw;
	background-color: white;
	opacity: 0;
	top: 100px;
	z-index: -1;
}


.Player_decades {
	position: fixed;
    margin: 72px auto;
    top: 0;
    transform: translateX(-50%);
    max-width: 450px;
	@media only screen and (max-width: 768px)  {
		transform: translate(0);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
        justify-content: center;
		left:0;
		right: 0;
		height: auto;
	}
}

.player-hidden {
	visibility: hidden;
}

.hidden {
	display: none;
}
